<template>
  <el-card shadow="never" style="margin: 30px auto 0;max-width: 1280px;">
    <div>欢迎您, {{typeName}} - {{name}}!</div>
    <div>
      <span>您可以</span>
      <el-button type="text" @click="logout">退出</el-button>
      <span>, 或者进入</span>
      <el-button type="text" @click="goAdmin">{{typeName}}系统</el-button>
    </div>
  </el-card>
</template>

<script>
import user from '@/models/User.js';

export default {
  name: 'home',
  data() {
    return {};
  },
  computed: {
    name() {
      return this.$store.state.user.name;
    },
    typeName() {
      return this.$store.state.user.typeName;
    },
    type() {
      return this.$store.state.user.type;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logout');
    },
    goAdmin() {
      user.toUserIndex();
    },
  },
};
</script>
