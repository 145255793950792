<template>
  <div class="form-container">
    <kk-form
      v-if="signInForm"
      :form-info="signInFormInfo"
      label-width="100px"
      @submit.native.prevent
      v-bind="$props"
    >
      <kk-form-item label="账户名称" form-item-info="name">
        <el-input
          placeholder="应用名称或公司简称"
          maxlength="20"
          v-model="signInForm.name"
          :disabled="signInFormInfo.name.disabled"
          show-word-limit
        />
      </kk-form-item>
      <kk-form-item label="公司名称" form-item-info="company_name">
        <el-input
          maxlength="20"
          v-model="signInForm.company_name"
          :disabled="signInFormInfo.company_name.disabled"
          placeholder="公司全称"
          show-word-limit
        />
      </kk-form-item>
      <kk-form-item label="邮箱" form-item-info="email">
        <el-input
          maxlength="50"
          v-model="signInForm.email"
          :disabled="signInFormInfo.email.disabled"
          placeholder="登录平台的邮箱地址"
          show-word-limit
        />
      </kk-form-item>
      <kk-form-item label="验证码" form-item-info="code" class="code-item">
        <el-input
          v-model="signInForm.code"
          :disabled="signInFormInfo.code.disabled"
          placeholder="请填写邮箱中收到的验证码"
          class="code-input"
        />
        <span v-show="!isWaiting">
          <div :class="`code-btn ${signInForm.email ? '':'disabled'}`" v-show="!isVerified && clickNum === 0" @click="getCode">获取验证码</div>
          <div :class="`code-btn ${signInForm.email ? '':'disabled'}`" v-show="isVerified && clickNum > 0" @click="getCode">点击重新获取</div>
        </span>
        <span v-show="isWaiting">
          <div class="code-btn disabled">已发送({{time}}s)</div>
        </span>
        <TencentCaptcha ref="resetCaptcha" app-id="2040348363" @pass="handlCaptchaPass" />
      </kk-form-item>
      <kk-form-item label="联系人" form-item-info="contact_infos_name">
        <el-input
          maxlength="20"
          v-model="signInForm.contact_infos_name"
          :disabled="signInFormInfo.contact_infos_name.disabled"
          placeholder="请填写联络人姓名"
          show-word-limit
        />
      </kk-form-item>
      <kk-form-item label="联系电话" form-item-info="contact_infos_tel">
        <el-input
          maxlength="11"
          v-model="signInForm.contact_infos_tel"
          :disabled="signInFormInfo.contact_infos_tel.disabled"
          placeholder="请填写手机号码"
          show-word-limit
        />
      </kk-form-item>
    </kk-form>
    <div slot="footer">
      <div class="form-btn" @click="signInAdvertiser"><img src="/effect/static/assets/sign_in_btn.png" width="292px" height="77px"/></div>
    </div>
  </div>
</template>
<script>
import FormModel from '@/models/FormModel.js';
import TencentCaptcha from '@/components/TencentCaptcha';

export default {
  name: 'signInForm',
  components: {
    TencentCaptcha,
  },
  data() {
    return {
      signInForm: null,
      signInFormInfo: null,
      isVerified: false,
      clickNum: 0,
      isWaiting: false,
      time: 59,
      // isEmailLegal: -1,
    };
  },
  props: {
    labelPosition: {
      type: String,
      default: 'right',
    },
  },
  created() {
    // 注册表单
    const signInFormModel = new FormModel();
    this.signInFormModel = signInFormModel;
    signInFormModel.addItems([
      ['name', {
        label: '账户名称',
        validate: (val) => {
          if (!val) {
            return '请填写应用名称或者公司简称';
          }
        },
      }],
      ['company_name', {
        label: '公司名称',
        default: '',
        validate: (val) => {
          if (!val) {
            return '请填写公司名称';
          }
        },
      }],
      ['email', {
        label: '邮箱',
        default: '',
        validate: (val) => {
          const emailPattern = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
          if (!val) {
            return '请填写邮箱账号';
          }
          if (!emailPattern.test(val)) {
            return '请填写正确的邮箱格式';
          }
        },
      }],
      ['code', {
        label: '验证码',
        default: '',
        validate: (val) => {
          const codePattern = /^\d{4}$/;
          if (!val) {
            return '请填写验证码';
          }
          if (!codePattern.test(val)) {
            return '验证码有误，请重新填写';
          }
        },
      }],
      ['contact_infos_name', {
        label: '联系人',
        default: '',
        validate: (val) => {
          if (!val) {
            return '请填写联系人姓名';
          }
        },
      }],
      ['contact_infos_tel', {
        label: '联系电话',
        default: '',
        validate: (val) => {
          const phonePattern = /^\d+$/;
          if (!val) {
            return '请填写手机号码';
          }
          if (!phonePattern.test(val)) {
            return '请填写数字';
          }
        },
      }],
    ]);
    signInFormModel.initForm();
    this.signInForm = signInFormModel.form;
    this.signInFormInfo = signInFormModel.formInfo;
  },
  watch: {
    signInVisible(val) {
      if (!val) {
        this.signInFormModel.initForm();
      }
    },
  },
  methods: {
    async signInAdvertiser() {
      if (!this.signInFormModel.validateForm()) return;
      const data = this.signInFormModel.constructUploadData();
      data.contact_infos = [{
        name: data.contact_infos_name,
        tel: data.contact_infos_tel,
      }];
      data.login_name = data.email;
      data.contact_infos_name = undefined;
      data.contact_infos_tel = undefined;
      try {
        const res = await this.$axios.postJson({
          url: '/kyle/client/register',
          data,
        });
        if (res.message === 'ok') {
          this.$emit('switch-dialog', data.email);
        } else {
          this.$message.error(res.message || '注册失败');
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    async getCode() {
      const res = await this.$axios({
        url: '/kyle/client/register/verify',
        method: 'get',
        params: {
          email: this.signInForm.email,
        },
      });
      if (res && res.message === 'ok') {
        this.openResetCaptcha();
      }
    },
    openResetCaptcha() {
      try {
        this.$refs.resetCaptcha.renderCaptcha();
      } catch (err) {
        this.$reportSentry('检测到用户码初始化错误', {
          action: 'resetPass',
          extra: err,
        });
        this.$message.error('加载验证码错误, 请刷新页面重试');
      }
    },
    async handlCaptchaPass(passTicket) {
      const data = {
        challenge: passTicket,
        email: this.signInForm.email,
      };
      try {
        const res = await this.$axios({
          url: '/kyle/common/register_advertiser/verified_code/get',
          method: 'get',
          params: data,
        });
        if (res.message === 'ok') {
          this.isWaiting = true;
          this.clickNum += 1;
          this.isVerified = true;
          await new Promise((resolve) => {
            let timer = null;
            timer = setInterval(() => {
              this.time -= 1;
              if (this.time <= 0) {
                resolve(timer);
              }
            }, 1000);
          }).then(timer => clearInterval(timer));
          this.isWaiting = false;
          this.time = 59;
        } else {
          this.$message.error(res.message || '获取验证码失败');
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .form-btn {
    cursor: pointer;
  }
  .code-btn {
    display: inline-block;
    width: 20%;
    text-align: center;
    border-left: none !important;
    border-radius: 0 10px 10px 0 !important;
    cursor: pointer;
  }
  .disabled {
    background: rgba(239, 239, 239, 0.3);
    color:rgba(16, 16, 16, 0.3);
  }
</style>
<style lang="scss">
  .form-container {
    .el-input, .code-btn {
      border: 1px solid #000000;
      border-radius: 10px;
      overflow: hidden;
    }
    input {
      border: none;
      overflow: hidden;
    }
    .el-input:hover {
      border: 1px solid #000000;;
    }
    .el-input:focus {
      border: 1px solid #000000;;
    }
  }
  .code-input {
    width: 80%;
    border-radius: 10px 0 0 10px !important;
  }
</style>
