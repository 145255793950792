<template>
  <div class="header">
    <div class="logo">
      <router-link to="/" title="后羿官网">
        <img src="https://festatic.v3mh.com/static-resource/img/20210706/tiny/logo_64x64.png" style="width: 26px; vertical-align: middle;" />
      </router-link>
      <el-divider direction="vertical" />
      <router-link to="/" title="后羿官网" style="color: #000;">后羿智投效果广告平台</router-link>
    </div>
    <div style="flex: 1;"></div>
    <div class="header-action">
      <div class="action-item" @click="signIn">注册</div>
      <div>|</div>
      <div v-if="!name" title="点击登录" class="action-item" @click="goLogin">登录</div>
      <el-popover
        v-if="name"
        placement="bottom"
        trigger="click"
        transition="el-zoom-in-top"
        :visible-arrow="false">
        <div class="drop-menu">
          <div class="drop-menu-item" @click="logout">
            <i class="el-icon-switch-button" /> 退出账户
          </div>
        </div>
        <div class="action-item" slot="reference">
          <el-avatar :size="30" icon="el-icon-user-solid" />
          <div style="display: inline-block; margin: 0 4px 0 8px; max-width: 120px; font-size: 12px;">
            <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" :title="name">{{name}}</div>
            <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" :title="name">ID: {{uid}}</div>
          </div>
          <i class="icon el-icon-arrow-down" />
        </div>
      </el-popover>
      <div v-if="environment !== 'production'" class="action-item">
        <i class="icon el-icon-coordinate"></i>
        {{envTitle}}
      </div>
    </div>
    <signInDialog ref="signInDialog" :is-login-page="isLoginPage" />
  </div>
</template>

<script>
import signInDialog from '@/components/SignInDialog';

export default {
  name: 'appHeader',
  components: {
    signInDialog,
  },
  computed: {
    environment() {
      return this.$store.state.app.environment;
    },
    mode() {
      return this.$store.state.app.mode;
    },
    name() {
      return this.$store.state.user.name;
    },
    uid() {
      return this.$store.state.user.id;
    },
    breadcrumb() {
      return this.$store.state.app.breadcrumb;
    },
    envTitle() {
      if (this.environment === 'production') {
        return '生产环境';
      }
      if (this.environment === 'stag') {
        return 'stag环境';
      }
      if (this.environment === 'development') {
        return 'dev环境';
      }
      if (this.environment === 'local') {
        return `本地-${this.mode}`;
      }
      return `未知环境-${this.environment}`;
    },
    isLoginPage() {
      return this.$route.name === 'Login';
    },
  },
  methods: {
    goHome() {
      this.$router.push('/');
    },
    goUserCenter() {
      this.$router.push({
        name: 'accountCenter',
      });
    },
    async logout() {
      this.$store.dispatch('user/logout');
    },
    goLogin() {
      if (this.$route.name === 'Login') {
        console.log('您当前正在登录页');
        return;
      }
      this.$router.push({ name: 'Login' });
    },
    signIn() {
      this.$refs.signInDialog.openSignInDialog();
    },
  },
};
</script>

<style lang="scss" scoped>
$height: 60px;

.header {
  position: relative;
  z-index: 10;
  height: $height;
  min-height: $height;
  background-color: #fff;
  box-shadow: 0 2px 8px #f0f1f2;
  display: flex;
}

.logo {
  height: $height;
  padding-left: 24px;
  overflow: hidden;
  line-height: $height;
  text-decoration: none;
}

.header-action {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  color: #909399;

  .action-item {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 12px;
    cursor: pointer;
    transition: all .3s ease;
    font-size: 14px;
    min-width: 5em;
    text-align: center;
    justify-content: center;

    &:hover {
      background-color: rgba(0, 0, 0, .025);
      color: #636363;
      // color: #4987ec;
    }
  }
}

.drop-menu {
  .drop-menu-item {
    cursor: pointer;
    margin: 0 -12px;
    padding: 6px 12px;
    color: #909399;

    &:hover {
      background-color: rgba(0, 0, 0, .025);
      color: #636363;
    }
  }
}
</style>
