import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/home.vue';

Vue.use(VueRouter);

const routes = [{
  path: '/',
  name: 'Home',
  component: Home,
  redirect: { name: 'Introduction' },
}, {
  path: '/login',
  name: 'Login',
  component: () => import('../views/login.vue'),
}, {
  path: '/error',
  name: 'Error',
  component: () => import('../views/error.vue'),
}, {
  path: '/introduction',
  name: 'Introduction',
  component: () => import('../views/introduction/views'),
}];

const router = new VueRouter({
  routes,
});

export default router;
