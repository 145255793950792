/* eslint-disable import/no-cycle */
import user from '@/models/User';
import { Message } from 'element-ui';

const appVuex = {
  namespaced: true,
  state: {
    isLogin: true,
    id: user.id,
    name: user.name,
    type: user.type,
    typeName: user.typeName,
    company: user.company,
    username: user.username,
    uid: user.uid,

    permission: {},
  },
  mutations: {
    setUserInfo(state, userInstance) {
      state.isLogin = userInstance.isLogin;
      state.id = userInstance.id;
      state.name = userInstance.name;
      state.type = userInstance.type;
      state.typeName = userInstance.typeName;
      state.company = userInstance.company;
      state.username = userInstance.username;
      state.uid = userInstance.uid;
      state.permission = userInstance.permission;
    },
  },
  actions: {
    async login(store, { username, password, challenge }) {
      try {
        await user.login(username, password, challenge);
      } catch (err) {
        Message.error(err.message || '登录失败');
      }
    },

    async logout({ commit }) {
      // 登出操作
      try {
        await user.logout();
        commit('setUserInfo', user);
      } catch (err) {
        Message.error(err.message || '登出失败');
        return;
      }

      // 调整至登录页
      const href = '/effect/#/login';
      window.location.href = href;
    },

    async setUserInfo({ commit }) {
      await user.getInfo();
      commit('setUserInfo', user);
    },
  },
};

export default appVuex;
