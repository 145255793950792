<template>
  <el-dialog
    :visible.sync="successVisible"
    :close-on-click-modal="false"
    :modal="false"
    append-to-body
    center
    custom-class="signInDialogClass successDialog"
  >
    <div><img src="/effect/static/assets/sign_in_success.png" width="252px" height="154px"/></div>
    <div class="success-title">账号注册成功</div>
    <div class="success-sub-title">我们已将登录密码以邮件的形式发送到您的邮箱，请用初始密码登录账号</div>
    <el-button class="success-btn" v-if="!isLoginPage"><router-link :to="{ name: 'Login', query: {email: this.email}}" target="_blank">进入投放管理平台</router-link></el-button>
    <div slot="footer">
      <el-button type="text" class="back-btn" @click="successVisible = false">返 回</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'successDialog',
  props: {
    isLoginPage: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      successVisible: false,
    };
  },
  methods: {
    openSuccess() {
      this.successVisible = true;
    },
  },
};
</script>
<style lang="scss">
.successDialog {
    .el-dialog__body {
        text-align: center;
        padding-top: 70px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .el-dialog__footer {
        padding-top: 4px;
    }
    .success-title {
        font-size: 48px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #1E1E1E;
        line-height: 46px;
        margin-top: 62px;
        margin-bottom: 30px;
    }
    .success-sub-title {
        width: 390px;
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #1E1E1E;
        line-height: 36px;
        margin-bottom: 100px;
    }
    .success-btn {
        background: #FCDA49;
        border-radius: 38px;
        width: 424px;
        height: 77px;
        text-align: center;
        border: none;
        a {
            font-size: 36px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
            display: inline-block;
            line-height: 34px;
            height: 34px;
        }
    }
    .back-btn {
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #7F7ABF;
    }
}
</style>
