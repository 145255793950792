/**
 * @author 圈圈
 * @description 用于提供通用的表单数据配置
 * @todo trigger 降频, 执行延迟变为可配置
 */

import { isFunction, deepClone, debounce } from '@/assets/js/utils';

const EventSymbol = Symbol('event');
const ConfigSymbol = Symbol('config');
/** 初始化表单锁, 避免初始化时触发更新事件 */
let initStatus = false;

const OperationTypes = {
  SET: 'set',
  ADD: 'add',
  DELETE: 'delete',
};

const { hasOwnProperty } = Object.prototype;
const hasOwn = (val, key) => hasOwnProperty.call(val, key);
const innerPropRegex = /^__[\S|\s]+__$/;
const isInnerProp = key => innerPropRegex.test(key);
const isObject = val => (typeof val === 'object' && val !== null);

/** 获取值, 如果是函数, 返回函数执行结果 */
function getValue(valueConfig, ...args) {
  if (isFunction(valueConfig)) {
    return valueConfig(...args);
  }
  return valueConfig;
}

/** 空值判断 */
function isEmptyVal(val) {
  return val === '' || val === undefined || val === null || (Array.isArray(val) && val.length === 0);
}

function createReactiveObject(val, handle) {
  if (!isObject(val)) {
    return val;
  }
  Object.keys(val).forEach((key) => {
    if (isObject(val[key])) {
      // eslint-disable-next-line no-param-reassign
      val[key] = createReactiveObject(val[key], handle);
    }
  });
  return new Proxy(val, {
    set(target, propKey, value, receiver) {
      if (isInnerProp(propKey)) {
        return Reflect.set(target, propKey, value, receiver);
      }

      const oldValue = target[propKey];
      const newValue = isObject(value) ? createReactiveObject(value, handle) : value;
      if (oldValue !== newValue) {
        handle();
      }
      return Reflect.set(target, propKey, newValue, receiver);
    },
    deleteProperty(target, propKey) {
      if (hasOwn(target, propKey)) {
        handle();
      }
      return Reflect.deleteProperty(target, propKey);
    },
  });
}

/** 延迟执行函数 */
function delayExec(fuc) {
  Promise.resolve().then(fuc);
}

class FormModel {
  constructor(config = {}) {
    /** 表单约束条件 */
    this.formConfig = new Map();
    /** 表单数据 */
    this.form = null;
    this.formInfo = null;
    /** 表单状态, new-新建, inited-初始化, change-已修改, save-调用过构造上传数据函数 */
    this.status = 'new';
    /** 事件池 */
    this[EventSymbol] = Object.create(null);
    /** 配置 */
    this[ConfigSymbol] = Object.create(null);
    /** 是否禁用表单 */
    this[ConfigSymbol].disabled = isEmptyVal(config.disabled) ? false : config.disabled;
    /** 是否使用自反应的表单数据, 如果为false, 则不使用监听数据变更 Proxy */
    this[ConfigSymbol].reactiveForm = isEmptyVal(config.reactiveForm) ? true : config.reactiveForm;
  }

  /**
   * 批量添加表单项
   * @param {Array} itemList 表单项配置集合
   */
  addItems(itemList) {
    if (!Array.isArray(itemList)) {
      console.error(itemList);
      throw new Error('FormModel error: addItems 参数为配置数组');
    }
    itemList.forEach((item) => {
      if (!Array.isArray(item)) {
        console.error(item);
        throw new Error('FormModel error: 配置格式错误');
      }
      this.addItem(item[0], item[1]);
    });
  }

  /**
   * 添加单个表单项
   * @param {String} keyName
   * @param {Object} config
   */
  addItem(keyName = '', config = {}) {
    if (!keyName) {
      return;
    }

    /** 默认配置 */
    const target = {
      /** 字段描述 */
      label: '',
      /** 初始化方法, 支持函数 <initArgument> */
      init: null,
      /** 默认值, 支持函数 <initArgument> */
      default: '',
      /** 是否有效, 支持函数 <formValue> */
      active: true,
      /** 是否隐藏, 支持函数 <formValue> */
      hidden: false,
      /** 禁用, 支持函数 <formValue> */
      disabled: false,
      /** 是否必填, 支持函数 <formValue> */
      required: false,
      /** 校验, 支持函数 <formValue> */
      validate: null,
      /** 变更事件 */
      onChange: null,
    };

    Object.keys(config).forEach((key) => {
      target[key] = config[key];
    });

    this.formConfig.set(keyName, target);
    this.form = null;
  }

  /**
   * 初始化表单, 传参数表示根据参数初始化, 不传则使用配置的默认值
   * @param {Object} source 初始化数据源
   */
  initForm(source = {}) {
    if (!source) {
      // eslint-disable-next-line no-param-reassign
      source = {};
    }
    initStatus = true;
    /** 延迟执行表单状态更新 */
    const debounceUpdateFormInfo = debounce(() => {
      this.buildFormInfo();
    }, 200);

    // 初始化表单对象
    let formValue;
    if (this.form) {
      formValue = this.form;
    } else if (!this[ConfigSymbol].reactiveForm) {
      formValue = Object.create(null);
    } else {
      formValue = new Proxy(Object.create(null), {
        /** 代理 set */
        set: (target, propKey, value, receiver) => {
          if (propKey === '__kk_isProxy__') {
            return false;
          }

          if (isInnerProp(propKey)) {
            return Reflect.set(target, propKey, value, receiver);
          }

          // 禁用逻辑
          if (!initStatus && this[ConfigSymbol].disabled) {
            return true;
          }

          const hadKey = hasOwn(target, propKey);
          const oldValue = target[propKey];

          const newValue = createReactiveObject(value, () => {
            // 修改代码执行顺序, 保证新值已经设置上
            delayExec(() => {
              this.trigger(`${OperationTypes.SET}:${String(propKey)}`, {
                key: propKey,
                oldValue: null,
                newValue: target[propKey],
                form: this.form,
              });
              // 变更触发校验
              this.validateItem(propKey);
            });
          });
          // 监听变化自动执行表单状态检查, 目的是初始化时的变更不会触发校验和更新
          if (!initStatus) {
            const extraInfo = {
              key: propKey,
              oldValue,
              newValue,
              form: this.form,
            };
            delayExec(() => {
              if (!hadKey) {
                this.trigger(`${OperationTypes.ADD}:${String(propKey)}`, extraInfo);
              } else if (oldValue !== value) {
                this.trigger(`${OperationTypes.SET}:${String(propKey)}`, extraInfo);
                this.validateItem(propKey);
                debounceUpdateFormInfo();
              }
            });
          }
          return Reflect.set(target, propKey, newValue, receiver);
        },
        /** 代理 delete */
        deleteProperty: (target, propKey) => {
          if (isInnerProp(propKey)) {
            return Reflect.deleteProperty(target, propKey);
          }
          // 禁用逻辑
          if (this[ConfigSymbol].disabled) {
            return;
          }
          if (hasOwn(target, propKey)) {
            this.trigger(`${OperationTypes.DELETE}:${String(propKey)}`);
          }
          return Reflect.deleteProperty(target, propKey);
        },
        /** 代理 get */
        get: (target, propKey, receiver) => {
          if (propKey === '__kk_isProxy__') {
            return true;
          }
          return Reflect.get(target, propKey, receiver);
        },
      });
    }

    // 获取表单值
    this.formConfig.forEach((config, key) => {
      formValue[key] = formValue[key] || '';

      // 未配置初始化逻辑, 尝试查找同名字段
      if (!config.init || typeof config.init === 'string') {
        const keyName = config.init || key;
        formValue[key] = hasOwn(source, keyName) ? source[keyName] : getValue(config.default, source);
      } else if (isFunction(config.init)) {
        formValue[key] = getValue(config.init, source);
      } else {
        throw new Error(`FormModel Error: 属性 "${key}" 初始化值出错, 请检查你的配置`);
      }
    });

    this.form = formValue;
    this.buildFormInfo();
    this.clearError();
    this.status = 'inited';
    initStatus = false;
  }

  /** 设置表单配置状态 */
  buildFormInfo() {
    // 获取表单配置
    this.formConfig.forEach((config, key) => {
      this.buildItemInfo(key);
    });
  }

  /** 设置单项表单配置状态 */
  buildItemInfo(keyName) {
    this.formInfo = this.formInfo || {};
    const formValue = this.form;
    const { formInfo } = this;
    const info = formInfo[keyName] || {};
    const config = this.formConfig.get(keyName);
    info.active = getValue(config.active, formValue);
    info.disabled = getValue(config.disabled, formValue);
    info.hidden = getValue(config.hidden, formValue);
    info.required = getValue(config.required, formValue);
    info.error = info.error || '';

    formInfo[keyName] = info;
  }

  clearError() {
    if (!this.formInfo) {
      return;
    }
    Object.keys(this.formInfo).forEach((key) => {
      // eslint-disable-next-line no-param-reassign
      this.formInfo[key].error = '';
    });
  }

  /**
   * 表单数据校验
   * @returns {boolean} true-校验通过, false-校验不通过
   */
  validateForm() {
    let isPass = true;
    const { formConfig } = this;

    formConfig.forEach((config, key) => {
      const result = this.validateItem(key);
      isPass = isPass && result;
    });

    return isPass;
  }

  /**
   * 表单数据校验
   * @returns {boolean} true-校验通过, false-校验不通过
   */
  validateItem(keyName) {
    const { form, formInfo, formConfig } = this;
    const config = formConfig.get(keyName);
    const info = formInfo[keyName];
    const targetValue = form[keyName];

    if (!config) {
      return;
    }
    // 清空原始错误信息
    info.error = '';

    // 判断字段是否生效中, 跳过失效的字段
    if (!getValue(info.active, form)) {
      return true;
    }

    // 必填校验
    if (getValue(config.required, form) === true) {
      if (isEmptyVal(targetValue)) {
        info.error = '此项必填';
        return false;
      }
    }

    // 检查 validate 配置
    if (config.validate instanceof RegExp) { // 正则校验
      if (!config.validate.test(targetValue)) {
        info.error = '格式有误';
        return false;
      }
    } else if (isFunction(config.validate)) { // 自定义校验
      const result = config.validate(targetValue, form);
      if (result && typeof result === 'string') {
        info.error = result;
        return false;
      }
    } else if (isObject(config.validate)) { // todo: 校验配置
      console.warn('已配置的形式进行校验尚未支持');
    }
    return true;
  }

  /**
   * 构造上传数据
   */
  constructUploadData() {
    const result = {};
    const { form, formInfo, formConfig } = this;
    formConfig.forEach((value, key) => {
      // 跳过失效的字段
      if (!formInfo[key].active) {
        return;
      }
      result[key] = form[key];
    });
    this.status = 'save';
    return deepClone(result);
  }

  // ---------------- 事件模型 ------------------------------------------------------

  addEventListener(evtName, fuc, once = false) {
    this[EventSymbol][evtName] = this[EventSymbol][evtName] || new Set();
    this[EventSymbol][evtName].add({
      handle: fuc,
      once,
    });
  }

  removeEventListener(evtName, fuc) {
    if (!this[EventSymbol][evtName]) {
      return;
    }
    this[EventSymbol][evtName].forEach((item) => {
      if (item.handle === fuc) {
        this[EventSymbol][evtName].delete(item);
      }
    });
  }

  trigger(evtName, val) {
    if (!evtName || !this.form) return;

    this.status = 'change';

    // 配置的 onChange 函数
    const itemConfig = this.formConfig.get(val.key);
    if (itemConfig && isFunction(itemConfig.onChange)) {
      itemConfig.onChange.call(this, val, this.form);
    }

    // 绑定的事件监听函数
    const actions = this[EventSymbol][evtName];
    if (!actions) return;

    actions.forEach((action) => {
      const { handle, once } = action;
      handle.call(this, val);
      if (once) {
        actions.delete(action);
      }
    });
  }
}

export default FormModel;
