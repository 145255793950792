<template>
  <div ref="captcha">
  </div>
</template>

<script>
export default {
  name: 'TencentCaptcha',
  props: {
    appId: {
      type: String,
      default: '',
      required: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },

  async mounted() {
    await this.initCaptcha();
  },
  methods: {
    async initCaptcha() {
      if (typeof window.TencentCaptcha !== 'function') {
        await this.$utils.loadScript('https://turing.captcha.gtimg.com/TCaptcha.js');
      }
      this.instance = new window.TencentCaptcha(
        this.$refs.captcha,
        this.appId,
        async (res) => {
          // 验证结果，0：验证成功。2：用户主动关闭验证码。
          if (res.ret !== 0 || !res.ticket) {
            this.$emit('cancel');
            return;
          }
          try {
            await this.$axios.postJson({
              url: '/kyle/common/tencent_captcha/ticket/verify',
              data: {
                ticket: res.ticket,
                rand_str: res.randstr,
              },
            });

            this.$emit('pass', res.ticket);
          } catch (err) {
            this.$emit('fail', err);
            this.$message.error(err.message || '校验失败');
          }
        },
        this.extraBizParam,
      );
    },
    renderCaptcha() {
      if (!this.instance) {
        throw new Error('TencentCaptcha 未初始化');
      }
      this.instance.show();
    },
    destroyCaptcha() {
      if (!this.instance) {
        throw new Error('TencentCaptcha 未初始化');
      }
      this.instance.destroy();
    },
    getTicket() {
      if (!this.instance) {
        throw new Error('TencentCaptcha 未初始化');
      }
      return this.instance.getTicket();
    },
  },
};
</script>
