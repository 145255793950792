<template>
  <app-container id="app-container">
    <app-header title="后羿智投" />
    <app-body>
      <keep-alive :include="keepaliveList">
        <router-view :key="routerViewKey" />
      </keep-alive>
    </app-body>
    <app-footer v-if="this.$route.name !== 'Introduction'"/>
  </app-container>
</template>

<script>
import user from '@/models/User.js';
import AppContainer from '@/components/LayoutBlank/container.vue';
import AppHeader from '@/components/LayoutBlank/header.vue';
import AppFooter from '@/components/LayoutBlank/footer.vue';
import AppBody from '@/components/LayoutBlank/body.vue';

export default {
  name: 'App',
  components: {
    AppContainer,
    AppHeader,
    AppFooter,
    AppBody,
  },
  data() {
    return {
      /** app 状态 {'loading'|'loaded'|'403'|'error'} */
      appStatus: 'loading',
      keepaliveList: [],
    };
  },
  watch: {
    islogin(newVal) {
      if (newVal === false) {
        this.modalVisible = true;
      } else {
        this.modalVisible = false;
      }
    },
  },
  computed: {
    islogin() {
      return this.$store.state.user.isLogin;
    },
    // environment() {
    //   return this.$store.state.app.environment;
    // },
    routerViewKey() {
      return this.$route.path;
    },
  },
  async created() {
    console.log(user);
    if (!user.isLogin) {
      this.$store.dispatch('user/logout');
    }
    this.$store.dispatch('user/setUserInfo');
  },

};
</script>
