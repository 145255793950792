<template>
  <div>
    <el-dialog
      :visible.sync="signInVisible"
      :close-on-click-modal="false"
      :modal="false"
      append-to-body
      center
      custom-class="signInDialogClass"
    >
      <div slot="title" class="sign-in-title" >
        <div class="title">注册成为广告主</div>
        <div class="sub-title">专业顾问会第一时间与您联系</div>
      </div>
      <sign-in-form @switch-dialog="handleDialog"/>
    </el-dialog>
    <success-dialog ref="successDialog" :isLoginPage="isLoginPage" :email="email"/>
  </div>
</template>
<script>
import SignInForm from './signInForm';
import SuccessDialog from './successDialog';

export default {
  name: 'signInDialog',
  components: {
    SignInForm,
    SuccessDialog,
  },
  props: {
    isLoginPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      signInVisible: false,
      email: '',
    };
  },
  methods: {
    handleDialog(email) {
      this.signInVisible = false;
      this.$refs.successDialog.openSuccess();
      this.email = email;
    },
    openSignInDialog() {
      this.signInVisible = true;
    },
  },
};
</script>
<style lang="scss">
.signInDialogClass {
  width: 670px;
  border: 6px solid #FFE120;
  border-radius: 40px;
  box-shadow: none;
  .sign-in-title {
    .title {
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #1E1E1E;
        line-height: 36px;
        margin: 38px 0 17px 0;
    }
    .sub-title {
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #1E1E1E;
        line-height: 36px;
    }
  }
  .form-btn {
    text-align: center;
  }
}
</style>
