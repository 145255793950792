import sentry from '@/plugins/sentryNew.js';
import user from '@/models/User.js';

const isLocal = !!window.location.port;

if (!isLocal) {
  const sentryInstance = sentry.getInstance();

  sentryInstance.configureScope((scope) => {
    scope.setTag('subpage', 'common');
  });

  user.on('userInfo', () => {
    sentryInstance.configureScope((scope) => {
      scope.setUser({
        id: user.id,
        uid: user.uid,
        name: user.name,
        username: user.username,
        type: user.typeName,
        company: user.company,
        possessInfo: JSON.stringify(user.possessInfo || null),
      });
    });
  });
}
