/* eslint-disable import/no-cycle */
import Bowser from 'bowser';
import { getRunEnvironment } from '@/assets/js/utils';

/** 当前运行环境 */
const evt = getRunEnvironment();
/** 当前浏览器环境 */
const bowser = Bowser.parse(window.navigator.userAgent);

const appVuex = {
  namespaced: true,
  state: {
    environment: evt,
    bowser,
    /** @type {string} 调用接口模式, dev|stag|preview */
    mode: localStorage.getItem('requestMode') || 'stag',
    /** @type {boolean} 侧边栏 true: 展开, false: 收起 */
    sideNavIsCollapse: false,
    sideNavConfig: [],
    /** @type {boolean} 右侧导航 true: 展开, false: 收起 */
    rightDrawerVisible: false,
    breadcrumb: [],
    /** 全局通知 */
    globalNotices: [],
  },
  mutations: {
    openDrawer(state) {
      state.rightDrawerVisible = true;
    },
    closeDrawer(state) {
      state.rightDrawerVisible = false;
    },

    triggerSideNavIsCollapse(state) {
      state.sideNavIsCollapse = !state.sideNavIsCollapse;
    },

    setBreadcrumb(state, { breadcrumb }) {
      state.breadcrumb = breadcrumb;
    },
  },
  actions: {

  },
  getters: {
    // getNavConfig() {
    //   return viewController.sideNavConfig;
    // },
  },
};

export default appVuex;
