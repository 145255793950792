import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// 添加全局处理
import '@/assets/style/styles.scss';
import '@/plugins/axios';
import '@/plugins/utils';
import '@/plugins/element-ui';
import '@/plugins/kk-ui';
import '@/filter';
import '@/mixin';
import './plugins/sentry';

if (process.env.NODE_ENV === 'production') {
  Vue.config.productionTip = false;
  Vue.config.silent = true;

  // 生成环境开启  debugger 模式
  if (window.localStorage.getItem('kk_debugger')) {
    Vue.config.devtools = true;
    Vue.config.silent = false;
    Vue.config.productionTip = true;
  }
} else {
  Vue.config.performance = true;
  Vue.config.devtools = true;
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
